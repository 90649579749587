import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { toggleScan } from "../redux/actions";

const Scan = ({ scan }) => (
  <li className="scan-item">
   
    <span
      className="title"
    >
      <h1>✅ {scan.title}</h1>
      {scan.info && scan.info.map((info, index) => {
          return <div>
            <h4>{info?.techDesc}</h4>
            <p>{info?.tech}</p>
          </div>
        })
      }
    </span>
  </li>
);

// export default Scan;
export default connect(
  null,
  { toggleScan }
)(Scan);
