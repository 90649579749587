import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./redux/store";

import ScanApp from "./ScanApp";

const rootElement = document.getElementById("root");

console.log(process.env)

ReactDOM.render(
  <Provider store={store}>
    <ScanApp />
  </Provider>,
  rootElement
);
