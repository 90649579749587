import React from "react";
import { connect } from "react-redux";
import Scan from "./Scan";
// import { getScans } from "../redux/selectors";


const ScanList = ({ scans }) => (
  <div className="scan-list">
  {console.log('SCAN')}
  {console.log(scans && scans)}
  {console.log(scans && scans.content.length)}
  {console.log('END SCAN')}
    {scans && scans.content.length
      ? scans.content.map((scan, index) => {
          return <Scan key={`scan-${scan.id}`} scan={scan} />;
        })
      : "No result yet, you can start the scan!"}
      <button type="button">Save to Database</button>
  </div>
);

export default (ScanList);

