import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { addScan } from "../redux/actions";


class AddScan extends React.Component {
  static propTypes = {
    newScan: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = { input: "" };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      value: this.state.input
    };
    this.props.newScan(payload);
  }

  updateInput = input => {
    this.setState({ input });
  };

  render() {
    return (
      <div>
        <input
          onChange={e => this.updateInput(e.target.value)}
          value={this.state.input}
        />
        <button className="add-scan" onClick={this.onSubmit}>
          Start Scan
        </button>
      </div>
    );
  }
}

// export default connect(
//   null,
//   { addScan }
// )(AddScan);
export default AddScan;
