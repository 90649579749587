import { ADD_SCAN, TOGGLE_SCAN, SET_FILTER, SCAN_COMPLETE, SCAN_FAILED, NEW_SCAN } from "./actionTypes";

let nextScanId = 0;

export const addScan = content => ({
  type: ADD_SCAN,
  payload: {
    id: ++nextScanId,
    content
  }
});

export const newScan = content => ({
  type: NEW_SCAN  
});

export const scanComplete = content => ({
  type: SCAN_COMPLETE,
  payload: {
    content
  }
});

export const scanFailed = error => ({
    type: SCAN_FAILED,
    payload: error
});


export const toggleScan = id => ({
  type: TOGGLE_SCAN,
  payload: { id }
});

export const setFilter = filter => ({ type: SET_FILTER, payload: { filter } });
