import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import AddScan from "./components/AddScan";
import ScanList from "./components/ScanList";

import "./styles.css";
import scansMiddleware from "./redux/reducers/scansMiddleware";
class ScanApp extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    newScan: PropTypes.func.isRequired,
    response: PropTypes.object,
  };

  static defaultProps = {
    isLoading: false,
    response: "",
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { newScan, response, isLoading } = this.props;
    return (
      <div className="scan-app">
        <h1>Scrap website for information</h1>
        <AddScan newScan={newScan} />
        {(isLoading)?'Please Wait':''}
        <ScanList scans={response} />
        
      </div>
    );
  }
}

const mapStateToProps = ({ scans }) => {
  return {
    response: scans.response,
    isLoading: scans.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      newScan: (payload) => scansMiddleware.newScan(payload),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ScanApp);
