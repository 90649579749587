import axios from "axios";
var cheerio = require("cheerio");

import { newScan, scanComplete, scanFailed } from "./../actions";
export default class scansMiddleware {
  static newScan(websiteUrl) {
    console.log("test ", websiteUrl);
    return (dispatch) => {
      dispatch(newScan());
      scansMiddleware.scanningWebsite(dispatch, websiteUrl);
    };
  }

  static async scanningWebsite(dispatch, websiteUrl) {
    axios(      
      {
        method: 'get',
        url: `${process.env.REACT_APP_REST_URL}scanner/${websiteUrl.value}`,
        headers: {
          Authorization: 'Baerer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxLCJ1c2VybmFtZSI6ImFkZW1haGVuZHJhIiwiZW1haWwiOiJoZWxsb0BhZGVtYWhlbmRyYS5jb20iLCJwYXNzd29yZF9oYXNoIjoiJDJhJDA4JGI3QXhNQWVOejhJN01PekxEMlNmdnVSTFFmQzE4cGhkbU52NC9Fa0Q3WGJvS1JrMG9yalFHIiwiYXV0aF9rZXkiOiIkMmEkMDQkaXZzQ3hJRVFSd2lGdFZVN1BXcDJJdU43N0xCVjNWTktqOVJYd2MyOGEzclhldml0UnYwdS4iLCJjb25maXJtZWRfYXQiOjE2NjgyMjU1ODE5NTAsImJsb2NrZWRfYXQiOm51bGwsInJlZ2lzdHJhdGlvbl9pcCI6Ijo6MSIsImNyZWF0ZWRfYXQiOjE2NjgyMjU1ODE5NTAsInVwZGF0ZWRfYXQiOjE2NjgyMjU1ODE5NTAsImZsYWdzIjowLCJsYXN0X2xvZ2luX2F0IjpudWxsLCJzdGF0dXMiOjEsInR5cGVfdXNlciI6Mn0sImlhdCI6MTcxMzE1OTQxNCwiZXhwIjoxNzI2MTE5NDE0fQ.j_qWSA2iRP1wBZ2d80Icya6KIbF4CvTnMeWvHnUOltc'
        }
      }
    )
    .then((response) => {
      if (response && response.status === 200) {
        console.log(response.data.values)
        dispatch(scanComplete(response.data.values));
      }
    })
    .catch((error) => {
      dispatch(scanFailed())
      console.log(error);
    })
  }

  // static scanWebDirect(dispatch, websiteUrl) {
  //   var responses = "";

  //   axios
  //     .get(`https://w3techs.com/sites/info/q?url=${websiteUrl.value}`, {
  //       headers: {
  //         crossdomain: true,
  //         // "Accept-Language": "en-US,en;q=0.8",
  //         // Accept: "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8",
  //         "cross-origin-resource-policy": "*",
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Credentials": true,
  //         "Access-Control-Allow-Headers": "Content-Type",
  //         // "Content-Type": "application/json",
  //       },
  //       withCredentials: true,
  //       mode: 'no-cors',
  //       credentials: 'same-origin',
  //     })
  //     .then((urlResponse) => {
  //       const $ = cheerio.load(urlResponse.data);
  //     //   console.log(urlResponse.data)
  //       var data = [];
  //       var i = 0;
  //       $('.tech').find('.tech_main').find('p.si_h').each(function() {
  //         // data[i] = []
  //         var currObj = $(this)
  //         var title = currObj.text();
  //         var x = 0;
          
  //         var dataTechArr = []
  //         var x = 0
  //         if(i > 0){            
  //             while(!currObj.next().hasClass('si_h')){                
  //                 if(x == 0) {
  //                     var tech = currObj.parent('.si_h').next('div.si_tech.si_tech_np').text()
  //                     var techDesc = currObj.parent('.si_h').next('div.si_tech.si_tech_np').next('p.si_tech').text()
  //                     currObj = currObj.parent('.si_h').next('div.si_tech.si_tech_np').next('p.si_tech')
  //                 } else {
  //                     var tech = currObj.next('div.si_tech.si_tech_np').text()
  //                     var techDesc = currObj.next('div.si_tech.si_tech_np').next('p.si_tech').text()
  //                     currObj = currObj.next('div.si_tech.si_tech_np').next('p.si_tech')
  //                 }                
  //                 console.log(`${x}.) ${techDesc} ::> ${currObj.attr('class')}`)
  //                 if((tech != undefined && techDesc != undefined) && (tech != '' || techDesc != '')){
  //                     console.log(`${x}.) Not empty '${tech}' - '${techDesc}'`)
                      
  //                     dataTechArr.push({'tech': tech, 'techDesc': techDesc})
  //                 }
  //                 x++
  //                 if(x == 10)break;
  //             }
  //         } else {
  //             // dataTechArr = [];
  //             var tech = $(this).next('div.si_tech').text()
  //             var techDesc = $(this).next('div.si_tech').next('p.si_tech').text()
  //             console.log(`No One : '${tech}' - '${techDesc}'`)
  //             dataTechArr.push({'tech': tech, 'techDesc': techDesc})
  //         }
          
  //         // console.log(title)        
  //         data.push({'title': title, 'info': dataTechArr})        
  //         i++;
  //       });
  //       dispatch(scanComplete(data));
  //     //   res.send(urlResponse.data)
  //       // response.success(data, res);
  //     });  
  // }

  
}
