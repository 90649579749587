import { ADD_SCAN, SCAN_COMPLETE, NEW_SCAN } from "../actionTypes";

const initialState = {
  response: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_SCAN: {
      const { id, content } = action.payload;
      return {
        ...state,
        data: content,
      };
    }
    case NEW_SCAN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SCAN_COMPLETE: {
      return {
        ...state,
        response: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
