import { createStore, applyMiddleware} from "redux";
import rootReducer from "./reducers";
import thunk from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";

// import DevTools from '../containers/DevTools';

const enhancer = applyMiddleware(thunk);

// export default function configureStore(initialState) {
//   // Note: only Redux >= 3.1.0 supports passing enhancer as third argument.
//   // See https://github.com/rackt/redux/releases/tag/v3.1.0
//   return createStore(rootReducer, initialState, enhancer);
// }

export default createStore(rootReducer, composeWithDevTools(enhancer));
